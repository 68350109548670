
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/RubikMedium.ttf"); font-family : "Rubik"; font-weight : 500;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/RubikRegular.ttf"); font-family : "Rubik"; font-weight : 400;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/RubikRomanLight.ttf"); font-family : "Rubik"; font-weight : 300;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/RubikRomanBold.ttf"); font-family : "Rubik"; font-weight : 700;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/RubikRomanMedium.ttf"); font-family : "Rubik"; font-weight : 500;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/PoppinsMedium.ttf"); font-family : "Poppins"; font-weight : 500;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/RubikPuddlesRegular.ttf"); font-family : "Rubik Puddles"; font-weight : 400;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/RubikBold.ttf"); font-family : "Rubik"; font-weight : 700;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/RubikLight.ttf"); font-family : "Rubik"; font-weight : 300;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/PoppinsRegular.ttf"); font-family : "Poppins"; font-weight : 400;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/RobotoRegular.ttf"); font-family : "Roboto"; font-weight : 400;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/RubikRomanRegular.ttf"); font-family : "Rubik"; font-weight : 400;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/RubikRomanSemiBold.ttf"); font-family : "Rubik"; font-weight : 600;  }