:root { 
--black_900_7f:#0000007f; 
--gray_300_72:#dadada72; 
--blue_gray_100_01:#d8d8d8; 
--green_500:#4caf50; 
--black_900_1e:#0000001e; 
--black_900_66:#00000066; 
--black_900:#000000; 
--black_900_07:#00000007; 
--gray_200_01:#eeeeee; 
--blue_gray_700:#4e565e; 
--black_900_28:#00000028; 
--gray_600:#767676; 
--gray_700:#545454; 
--blue_gray_200:#b2c1cf; 
--gray_600_02:#838383; 
--gray_400:#c9c9c9; 
--blue_gray_100:#d2dce6; 
--gray_600_01:#707070; 
--gray_500:#909194; 
--blue_700:#1976d2; 
--blue_gray_400:#8d8d8d; 
--blue_gray_300:#9daebf; 
--amber_500:#ffc107; 
--gray_800:#3b3b3b; 
--blue_600:#2788e6; 
--gray_900:#2a2a2a; 
--lime_900:#8b4513; 
--gray_300_04:#dedede; 
--gray_200:#f0f0f0; 
--gray_300_03:#dddddd; 
--gray_600_04:#6b6b6b; 
--gray_300:#d7dfe8; 
--gray_300_02:#e2e2e2; 
--gray_600_03:#818181; 
--blue_gray_300_01:#8498ab; 
--gray_300_01:#d7e0e8; 
--gray_100:#f3f7fb; 
--gray_100_02:#f3f3f3; 
--indigo_100:#c1cdd9; 
--black_900_cc:#000000cc; 
--white_A700_01:#fdfdfd; 
--gray_100_01:#f1f5f9; 
--blue_300:#5cabf7; 
--white_A700:#ffffff; 
--black_900_16:#00000016; 
}